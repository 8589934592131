import React, { useState, useEffect } from "react";
import { Briefcase, GraduationCap } from "lucide-react";
import ScrollDown from "../../Utilities/ScrollDown/ScrollDown";
import ScreenHeading from "../../Utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../Utilities/ScrollService";
import Animations from "../../Utilities/Animations";
import "./Resume.css";

const ResumeSection = (props) => {
  const [activeSection, setActiveSection] = useState("experience");

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };

  const fadeInSubscription = ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  useEffect(() => {
    return () => fadeInSubscription.unsubscribe();
  }, [fadeInSubscription]);

  const experiences = [
    {
      title: "Développeur Web - Stage",
      company: "Citrus Développement",
      location: "Wimereux",
      period: "2024",
      description: [
        "Création d'un site vitrine",
        "Mise en place des formulaires PHP",
        "Page Admin avec suivi des réservations et messages de contact",
      ],
    },
    {
      title: "Commercial - VRP Multicarte",
      company: "Ranger FR",
      location: "Marcq-en-Barœul",
      period: "2019",
      description: [
        "Prospecter dans le but de vendre un produit",
        "Adapter son discours à la personne en face de soi",
      ],
    },
    {
      title: "Conseiller Clientèle",
      company: "DUACOM",
      location: "Douai",
      period: "2018",
      description: [
        "Prise en charge du client, une personne toutes les 3-4 minutes",
        "Gérer les situations complexes avec les clients",
      ],
    },
  ];

  const education = [
    {
      title: "MS Développeur PHP",
      company: "Greta",
      location: "Lens",
      period: "2024",
    },
    {
      title: "Développeur WEB",
      company: "OpenClassrooms",
      location: "Formation à distance",
      period: "2022",
      description: ["Titre Professionnel RNCP Niveau 5 (Bac+2)"],
    },
    {
      title: "BAC Économique & Social",
      company: "Lycée Ozanam",
      location: "Lille",
      period: "2015",
      description: ["Spécialité Sciences Politiques"],
    },
  ];

  return (
      <>
        <div className="resume-container screen-container fade-in" id={props.id || ""}>
          <ScreenHeading title={"Cursus"} subHeading={"Formation & Carrière"} />
          <div className="resume-content">
            <div className="resume-nav">
              <button
                  className={`resume-nav-button ${activeSection === "experience" ? "active" : ""}`}
                  onClick={() => setActiveSection("experience")}
              >
                <Briefcase size={20} />
                Expérience
              </button>
              <button
                  className={`resume-nav-button ${activeSection === "education" ? "active" : ""}`}
                  onClick={() => setActiveSection("education")}
              >
                <GraduationCap size={20} />
                Formation
              </button>
            </div>

            <div className="timeline-container">
              {activeSection === "experience" ? (
                  experiences.map((item, index) => (
                      <div className="timeline-item" key={index}>
                        <div className="timeline-header">
                          <h3 className="timeline-title">{item.title}</h3>
                          <p className="timeline-company">{item.company}</p>
                          <p className="timeline-details">{item.location} | {item.period}</p>
                        </div>
                        {item.description && (
                            <ul className="timeline-description">
                              {item.description.map((desc, idx) => (
                                  <li key={idx}>{desc}</li>
                              ))}
                            </ul>
                        )}
                      </div>
                  ))
              ) : (
                  education.map((item, index) => (
                      <div className="timeline-item" key={index}>
                        <div className="timeline-header">
                          <h3 className="timeline-title">{item.title}</h3>
                          <p className="timeline-company">{item.company}</p>
                          <p className="timeline-details">{item.location} | {item.period}</p>
                        </div>
                        {item.description && (
                            <ul className="timeline-description">
                              {item.description.map((desc, idx) => (
                                  <li key={idx}>{desc}</li>
                              ))}
                            </ul>
                        )}
                      </div>
                  ))
              )}
            </div>
          </div>
        </div>
        <ScrollDown />
      </>
  );
};

export default ResumeSection;