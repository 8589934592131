import React, { useState, useEffect } from "react";
import { ChevronRight, Coffee, Music, Book, Activity } from "lucide-react";
import ScrollDown from "../../Utilities/ScrollDown/ScrollDown";
import ScreenHeading from "../../Utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../Utilities/ScrollService";
import Animations from "../../Utilities/Animations";
import "./AboutMe.css";

const AboutMe = (props) => {
  const [activeTab, setActiveTab] = useState("skills");

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };

  const fadeInSubscription =
      ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  useEffect(() => {
    return () => fadeInSubscription.unsubscribe();
  }, [fadeInSubscription]);

  const skills = [
    {
      name: "Frontend & Frameworks",
      items: ["React.js", "Typescript", "NextJS", "Sass", "Tailwind CSS", "Laravel"],
    },
    {
      name: "Backend",
      items: ["Node.js", "PHP", "Python", "Express.js", "Composer"],
    },
    {
      name: "Base de données & Outils",
      items: ["MongoDB", "MySQL", "Git", "Docker"],
    },
    {
      name: "Best Practices",
      items: ["SEO", "WCAG", "Clean Code", "Testing"],
    },
    {
      name: "Logiciels",
      items: ["Suite Adobe", "Suite Microsoft Office", "Suite Affinity", "Figma", "Canva"],
    },
  ];

  const interests = [
    {
      icon: <Music size={24} />,
      title: "Musique",
      description: "Du jazz au rap en passant par la musique classique",
    },
    {
      icon: <Coffee size={24} />,
      title: "Café & Discussion",
      description: "Amateur de discussions enrichissantes",
    },
    {
      icon: <Book size={24} />,
      title: "Culture",
      description: "Littérature, cinéma et philosophie",
    },
    {
      icon: <Activity size={24} />,
      title: "Sport",
      description: "Rugby et boxe thaï",
    },
  ];

  return (
      <>
        <div className="about-me-container screen-container fade-in" id={props.id || ""}>
          <ScreenHeading title={"À Propos de Moi"} subHeading={"Pourquoi me Choisir?"} />
          <div className="about-me-content">
            <p className="about-me-description">
              Développeur Web diplômé d'OpenClassrooms, je suis animé par une passion
              dévorante pour la technologie et une curiosité insatiable. Spécialisé
              dans le développement frontend avec une solide expérience fullstack, je
              m'efforce de créer des expériences web exceptionnelles. Ma récente
              expérience chez Citrus Développement m'a permis de renforcer mes
              compétences en PHP et dans la gestion de projets web complets.
            </p>

            <div className="tabs-container">
              <button
                  className={`tab-button ${activeTab === "skills" ? "active" : ""}`}
                  onClick={() => setActiveTab("skills")}
              >
                Compétences
              </button>
              <button
                  className={`tab-button ${activeTab === "interests" ? "active" : ""}`}
                  onClick={() => setActiveTab("interests")}
              >
                Centres d'intérêt
              </button>
            </div>

            {activeTab === "skills" && (
                <div className="skills-grid">
                  {skills.map((category, idx) => (
                      <div key={idx} className="skill-category">
                        <h3 className="category-title">{category.name}</h3>
                        <div>
                          {category.items.map((skill, skillIdx) => (
                              <div key={skillIdx} className="skill-item">
                                <ChevronRight size={16} className="skill-icon" />
                                <span>{skill}</span>
                              </div>
                          ))}
                        </div>
                      </div>
                  ))}
                </div>
            )}

            {activeTab === "interests" && (
                <div className="interests-grid">
                  {interests.map((interest, idx) => (
                      <div key={idx} className="interest-card">
                        <div className="interest-icon">{interest.icon}</div>
                        <div className="interest-content">
                          <h3>{interest.title}</h3>
                          <p>{interest.description}</p>
                        </div>
                      </div>
                  ))}
                </div>
            )}
          </div>
        </div>
        <ScrollDown />
      </>
  );
};

export default AboutMe;